.check-point {
    font-size: 15px;
    margin-left: 15px;
}
.topper {
    border-radius: 4px;
    margin-bottom: 25px;
    padding: 10px;
    background-color: rgba(24, 143, 255, 0.151);
}
.ica-dashboard {
    padding: 20px 30px;
}
.dashboard-title {
    font-size: 22px;
    color: rgb(24, 144, 255);
}

.buttons-footer {
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
}
.dot {
    margin-right: 10px;
}
.submenu {
    margin-left: 45px;
}
.uppertext {
    padding: 3px;
    font-weight: 500;
    font-size: 15px;
}
.header-text {
    font-weight: 600;
    font-size: 16px;
}
.checkbox-d {
    box-shadow: 15px rgb(24, 144, 255);
}

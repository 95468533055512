.spin-verif.ant-spin-lg .ant-spin-dot i {
    width: 36px;
    height: 36px;
}

.spin-verif.ant-spin-lg .ant-spin-dot {
    font-size: 77px;
}

.spin-verif.ant-spin.ant-spin-show-text .ant-spin-text {
    font-size: 24px;
    margin-top: 15px;
    // display: block;
}


.extra-form-item .ant-form-item-control-input {
    min-height: 0;
}

.card-shadow {
    -webkit-box-shadow: 0px 0px 5px 0px darkgrey;
    -moz-box-shadow: 0px 0px 5px 0px darkgrey;
    box-shadow: 0px 0px 5px 0px darkgrey;
}

.is-clickable {
    cursor: pointer;
}

#components-layout-demo-top-side-2 .logo {
  min-width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;

  #title {
    text-align: center;
    color: white;
    margin-top: -2px;
  }
}


.blockchain-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;;
}

.green-blockchain-dot {
  @extend .blockchain-dot;
  background-color: rgb(50, 224, 44);
}

.red-blockchain-dot {
  @extend .blockchain-dot;
  background-color: red;
}
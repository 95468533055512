#login {
  display: none;
}
#register {
  display: none;
}
@media only screen and (max-width: 768px) {
  #login {
    display: block;
  }
  #register {
    display: block;
  }
}

.new-col {
  padding:15px
}
.danger-btn {
  background: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
  border-radius: 40px;
  margin-right: 10px;
  padding: 10px;
  font-family: Poppins;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em; 
  text-transform: uppercase;
  color: #fff;
  width: 126px;
  height: 39px;
}


.primary-bttn {
  border: 2px solid #2fbaca;
  border-radius: 40px;
  margin-right: 10px;
  padding: 9px;
  font-family: Poppins;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 126px;
  height: 39px;
  color: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
}

.primary-btn:hover {
  background: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
  color: #fff;
}
